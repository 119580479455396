<script lang="ts">
  import Popover from "src/partials/Popover.svelte"
  import Anchor from "src/partials/Anchor.svelte"
  import FlexColumn from "src/partials/FlexColumn.svelte"
  import Card from "src/partials/Card.svelte"
  import Heading from "src/partials/Heading.svelte"
  import {router} from "src/app/router"
  import {people} from "src/engine"

  document.title = "About"

  const hash = import.meta.env.VITE_BUILD_HASH
  const nprofile =
    "nprofile1qqs8u68j20079aun0fqd7a4v06awtxtxccr8l3crwt2246v43y4hgvs7ftf3f"
  const vancePubkey = "7e68f253dfe2f7937a40df76ac7ebae59966c6067fc70372d4aae995892b7432"
  // const vancePubkey = "7e68f253dfe2f7937a40df76ac7ebae59966c6067fc70372d4aae995892b7432"

  // Provide complete details in case they haven't loaded Nostr Growth's profile
  people.key(vancePubkey).update($person => ({
    ...$person,
    pubkey: vancePubkey,
    profile: {
      ...$person?.profile,
      name: "Nostr Growth",
    },
    zapper: {
      lnurl:
        "delpacifico@strike.me",
      callback: "https://api.geyser.fund/.well-known/lnurlp/arcmap",
      nostrPubkey: vancePubkey,
      allowsNostr: true,
      maxSendable: 5000000000,
      minSendable: 1000,
    },
  }))
</script>

<FlexColumn class="gap-8">
  <div class="flex flex-col items-center justify-center">
    <Heading>Nostr Growth</Heading>
    {#if hash}
      <p class="mt-1 text-xs">Running build {hash.slice(0, 8)}</p>
    {/if}
  </div>
  <!--<div class="grid grid-cols-1 gap-8 sm:grid-cols-2">
    <Card>
      <FlexColumn class="py-6 text-center">
        <h3 class="text-xl sm:h-12">Support development</h3>
        <p class="sm:h-24">
          All funds donated will be used to support server costs and development.
        </p>
        <div class="flex justify-center">
          <Anchor
            modal
            button
            accent
            href={router.at("people").of(vancePubkey).at("zap").toString()}>
            Zap the developer
          </Anchor>
        </div>
      </FlexColumn>
    </Card>
  </div>-->
  <div class="flex flex-col gap-4">
    
    <p class="text-center">
      Nostr Growth is built upon Coracle with 💜 by @<Anchor
        modal
        underline
        href={router.at("people").of(vancePubkey).toString()}>Vance</Anchor>
    </p>

    <p class="text-center">
      Original Coracle project built with 💜 by @<Anchor
        modal
        underline
        href={router.at("people").of("97c70a44366a6535c145b333f973ea86dfdc2d7a99da618c40c64705ad98e322").toString()}>hodlbod</Anchor>
    </p>
  </div>
</FlexColumn>
