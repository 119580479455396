<script lang="ts">
  import cx from "classnames"

  export let theme = "dark"
  export let onRemove = null

  const className = cx(
    $$props.class,
    "inline-block rounded-full border border-solid py-2 px-2 cy-chip",
    {
      "border-lightest": theme === "dark",
      "border-dark": theme === "light",
    }
  )
</script>

<div class={className} on:click>
  <div class="flex items-center gap-2 whitespace-nowrap">
    {#if onRemove}
      <i class="fa fa-times cursor-pointer" on:click|preventDefault={onRemove} />
    {/if}
    <slot />
  </div>
</div>
